exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-archive-js": () => import("./../../../src/pages/work/archive.js" /* webpackChunkName: "component---src-pages-work-archive-js" */),
  "component---src-templates-archive-work-js": () => import("./../../../src/templates/archive-work.js" /* webpackChunkName: "component---src-templates-archive-work-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-single-work-js": () => import("./../../../src/templates/single-work.js" /* webpackChunkName: "component---src-templates-single-work-js" */)
}

